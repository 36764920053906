<!--TODO если используется corner-cut, то необходимо указать shadow="never" -  пофиксить в дальнейшем-->
<template>
  <VHover #default="{hover}">
    <VCard
      class="rounded-x-lg card-wrapper"
      :ripple="false"
      flat
      active-class="card-shadow"
      :class="{'card--corner-cut' : cornerCut,
               'card-shadow' : (hover || shadowIsAlways) && !shadowIsNever}"
      v-bind="$attrs"
    >
      <div
        v-if="status"
        class="pb-2"
      >
        <ChipStatus :status="status" />
      </div>
      <slot />
    </VCard>
  </VHover>
</template>

<script>
import ChipStatus from '@/components/chips/ChipStatus.vue';

export default {
  name: 'CardWrapper',
  components: {
    ChipStatus,
  },
  props: {
    shadow: {
      type: String,
      validator: (v) => ['always', 'hover', 'never'].includes(v),
      default: 'always',
    },
    cornerCut: {
      type: Boolean,
      default: false,
    },
    status: {
      type: [String, Boolean],
      default: false,
    },
  },
  computed: {
    shadowIsAlways() {
      return this.shadow === 'always';
    },
    shadowIsNever() {
      return this.shadow === 'never';
    },
  },
};
</script>
<style lang="scss" scoped>

.card{
  &-wrapper{
    transition: all .3s;
    &.v-card--link{
      &:before{
        background: inherit;
      }
    }
    border-radius: inherit;

  }
  &-shadow {
    box-shadow: 0 2px 24px 0 rgba(11, 18, 24, 0.08) !important;
  }
  &--corner-cut {
    position: relative;
    filter: drop-shadow(0 2px 24px rgba(11, 18, 24, 0.08));
    background: linear-gradient(-135deg, transparent 16px, #fff 0) no-repeat!important;
    border-radius: 12px;

    &:after {
      content: '';
      width: 24px;
      height: 24px;
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block;
      background: linear-gradient(-135deg, transparent 16px, #D8D9DA 0) no-repeat !important;
      border-bottom-left-radius: 8px;
    }
  }
}
</style>
