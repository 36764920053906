<template>
  <TTChip
    v-if="date"
    color="tt-light-mono-4"
    :outlined="false"
    small
    data-test-label="chip-date"
  >
    <VIcon
      size="14"
      color="tt-light-mono-24"
      data-test="chip-date-icon"
      left
    >
      fa fa-calendar
    </VIcon>
    <span
      class="tt-text-caption tt-light-mono-64--text"
      data-test="chip-date-text"
    >
      {{ formattedDate }}
    </span>
  </TTChip>
</template>

<script>
export default {
  name: 'ChipDate',
  props: {
    date: {
      type: String,
      default: () => null,
    },
  },
  computed: {
    formattedDate() {
      return this.$dayjs(this.date, 'DD.MM.YYYY HH:mm').format('D MMMM в HH:mm');
    },
  },
};
</script>
